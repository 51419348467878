import React from "react"
import { useStaticQuery, graphql } from "gatsby"

import { PageLayout } from "../components/layouts"
import SEO from "../components/seo"
import { GroupSurvey } from "../components/survey"

import TextBlock from "../components/textBlock"

import {
  ScheduleVisitButton,
  RegisterForWebinarButton,
} from "../components/buttons"

import { QuotePanel } from "../components/quotes"

const LongTermCareGroupInsuranceSurvey = () => {
  const data = useStaticQuery(graphql`
    query {
      parallax: file(relativePath: { eq: "AdobeStock_250630318.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1920) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
    <PageLayout>
      <SEO
        title="Long Term Care Group Insurance Survey | Employee Benefits | LTCPG"
        ogDescription="Take our Long Term Care Insurance Survey. Just answer a few, quick questions. Corey Rieck and his team will contact you with information and education."
      />
      <TextBlock
        textPadded
        textLeft={
          <div>
            <h1>Long Term Care Interest Survey for Employees</h1>
            <p>
              Habersham Medical has added long term care planning as an additional
              benefit for employees and their spouses. You will have access to a
              long term care expert, Corey Rieck. He’s been solely focused on long
              term care planning for over 20 years. To assist you with your long
              term care education, there will be informational resources such as
              webinars, videos and virtual meetings made available to you.
            </p>
            <p>
              To support your interest and education in this new employee benefit,
              please take a moment to answer the following questions.
            </p>
            <GroupSurvey companyName="Habersham Medical" />
          </div>
        }
        textRight={
          <div>
            <img
              src="/images/AdobeStock_184356757.jpg"
              alt="Long Term Care Insurance Quote"
            />
          </div>
        }
      />
      <QuotePanel text="Hello World" person="John Doe" />
    </PageLayout>
  )
}

export default LongTermCareGroupInsuranceSurvey
